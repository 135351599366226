import React from "react";
import Hero from "assets/carrier/jobopeninghero.webp";
import Layout from "components/layout";
import Summary_icon from "assets/summary_icon.svg";
import Detect_icon from "assets/detect_icon.svg";
import Translation_icon from "assets/translation_icon.svg";
import { Link } from "gatsby";
import Arrow from "assets/rightarrow.svg";
import SEO from "components/seo";
import Wordcount from "assets/word_count.svg";
import DataAnonymizationTool from "assets/data-anonymization.svg";
import TopicTool from "assets/topic.svg";
import HERO_BANNER from "assets/toolsbanner.svg";
import ConsistencyIcon from "assets/consistency.svg";
import Glossary from "assets/glossary.svg";
const Tools = () => {
  const title = "Free Tools for Translation";
  const description =
    "Enhance your productivity and simplify your translation tasks with our free tools. Created to help you work faster and with greater accuracy.";
  const backgroundImageStyle = {
    backgroundImage: `url(${HERO_BANNER})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  const toolsData = [
    {
      imgurl: Translation_icon,
      title: "Translation Quality Assurance Tool",
      description:
        "Catch quality issues before delivery with this translation quality assurance tool.  Instantly check fluency, grammar, style, and consistency for better results.",
      link: "/tools/translation-quality-assurance",
    },
    {
      imgurl: TopicTool,
      title: "Topic Detection Tool",
      description:
        "Our topic identification tool will detect the main topic or subject field of your text, ensuring it's matched with a translator with the right expertise.",
      link: "/tools/topic-detection",
    },

    {
      imgurl: DataAnonymizationTool,
      title: "Data Anonymization Tool",
      description:
        "Secure your data with our anonymization tool. It redacts or encrypts identifiable information to ensure privacy compliance.",
      link: "/tools/data-anonymization",
    },
    {
      imgurl: Glossary,
      title: "Bilingual Glossary",
      description:
        "Generate bilingual glossaries instantly with our free AI-powered tool. It's the ideal solution for quickly creating glossaries tailored to your project.",
      link: "/tools/glossary-generator",
    },
    {
      imgurl: ConsistencyIcon,
      title: "Translation Consistency Checker",
      description:
        "Maintain consistent terminology in your translations with the consistency checker. Get a detailed report and consistency score to ensure accuracy.​",
      link: "/tools/translation-consistency-checker",
    },
    {
      imgurl: Detect_icon,
      title: "Detect Language",
      description:
        "Instantly detect and identify the language of any text. Use our language detection tool to find out what language you're dealing with quickly. Try it for free!",
      link: "/tools/language-detector",
    },
    {
      imgurl: Summary_icon,
      title: "Summary Generator",
      description:
        "The Tomedes summarization tool creates summaries of texts. You simply input a URL or paste in copy and the tool summarizes the information you have fed in. Ideal for use by translators, writers, content marketers and others who work on translation or with words on a daily basis.",
      link: "/tools/text-summarizer",
    },

    {
      imgurl: Wordcount,
      title: "Word Count Ratio Tool",
      description:
        "No more guessing the word count. Turn your source word count into an accurate target word count with our free online word counter tool. Perfect for students, translators, and professionals.",
      link: "/tools/word-count-ratio",
    },
  ];
  return (
    <Layout>
      <SEO title={title} description={description} slug="/tools" />
      <div>
        <section
          className="px-4  py-12 lg:h-[393px] flex items-center"
          style={backgroundImageStyle}
        >
          <div className="pt-5  py-5 max-w-7xl mx-auto  flex justify-between gap-12 ">
            <div className="flex flex-col gap-12  text-[#ffffff]">
              <h1 className=" font-bold text-[28px] md:text-[64px] leading-8 md:leading-[77px] text-white font-primary">
                Free Tools for Translation
              </h1>
              <p className=" font-opensans text-start text-base md:text-lg font-base leading-7 font-normal   ">
                Improve your productivity and make your translation work easier
                with our free tools. Designed to help translators work faster
                and more accurately.
              </p>
            </div>
            <div className="lg:w-[34%] hidden lg:block"></div>
          </div>
        </section>
        <div className="bg-[#F5F5F5]  py-[40px] lg:py-28 px-4">
          <div className=" max-w-7xl mx-auto  grid lg:grid-cols-3 gap-12">
            {toolsData.map((item, i) => (
              <Link
                key={i}
                href={item.link}
                className="bg-white p-4 md:p-6 hover:bg-[#F0F5FF] outline-none  border border-white hover:border hover:border-[#5B93FF] shadow-lg rounded-lg"
              >
                <div className="">
                  <img src={item.imgurl} alt="icon" className="w-12 h-12" />

                  <p className=" mt-6 text-[#0A2641] font-opensans leading-6 text-lg font-bold flex gap-2 items-center">
                    {item.title} <img src={Arrow} alt="arrow_icon" />
                  </p>

                  <p className="text-[#575757] font-opensans leading-6 font-normal text-base mt-4">
                    {item.description}
                  </p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Tools;
